import React from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import isEmpty from "is-empty";

// MUI Imports
import { Snackbar } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';

// actions
import { clearFlash } from '../../actions';

const Flash = (props) => {

  const useStyles = makeStyles({
    root: {}
  });

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    // clear redux message
    props.clearFlash()
  };

  const classes = useStyles();

  return(
    <Snackbar className={classes.root}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      open={!isEmpty(props.flash)}
      autoHideDuration={6000}
      onClose={handleClose}
    >
      {/* error, warning, info, success */}
      <Alert elevation={6} variant="filled" severity={props.flash.type}>
        {props.flash.text}
      </Alert>
    </Snackbar>
  )
}

const mapDispatchToProps = {
  clearFlash
};

const mapStateToProps = state => {
  return {
    flash: state.flash
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Flash);
