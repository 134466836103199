export const buoys = {
  byId: {
    44091: {
      id: 44091,
      name: 'Barnegate, NJ',
      location: [39.778, 73.769]
    },
    44065: {
      id: 44065,
      name: 'NY Harbor',
      location: [40.369, 73.703]
    },
    44025: {
      id: 44025,
      name: 'Long Island',
      location: [40.251, 73.164]
    },
    44066: {
      id: 44066,
      name: 'Texas Tower',
      location: [39.618, 72.644]
    },
    44017: {
      id: 44017,
      name: 'Montauk Point',
      location: [40.693, 72.049]
    },
    44009: {
      id: 44009,
      name: 'Delaware Bay',
      location: [38.457, 74.702]
    },
    44097: {
      id: 44097,
      name: 'Block Island',
      location: [40.969, 71.127]
    },

    46232: {
      id: 46232,
      name: 'Point Loma South',
      location: [32.517, 117.425]
    },
    46258: {
      id: 46258,
      name: 'Mission Bay West',
      location: [32.752, 117.501]
    },
    46225: {
      id: 46225,
      name: 'Torrey Pines Outer',
      location: [32.933, 117.391]
    },
    46266: {
      id: 46266,
      name: 'Del Mar Nearshore',
      location: [32.957, 117.279]
    },
    46254: {
      id: 46254,
      name: 'Scripps Nearshore',
      location: [32.868, 117.267]
    },
    46224: {
      id: 46224,
      name: 'Oceanside Offshore',
      location: [33.178, 117.472]
    },
    46253: {
      id: 46253,
      name: 'San Pedro South',
      location: [33.576, 118.181]
    },
    46222: {
      id: 46222,
      name: 'San Pedro',
      location: [33.618, 118.317]
    },
    46256: {
      id: 46256,
      name: 'Long Beach Channel',
      location: [33.700, 118.201]
    },
    46025: {
      id: 46025,
      name: 'Santa Monica Basin',
      location: [33.758, 119.044]
    },
    46221: {
      id: 46221,
      name: 'Santa Monica Bay',
      location: [33.860, 118.641]
    },
    46268: {
      id: 46268,
      name: 'Topanga Nearshore',
      location: [34.022, 118.578]
    },
  },
  allIds: [44091, 44065, 44025, 44066, 44017, 44009, 44097, 46232, 46258, 46225, 46266, 46254, 46224, 46253, 46222, 46256, 46025, 46221, 46268]
}
