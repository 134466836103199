import { colors } from './colors';

export const theme = {
  palette: {
    type: 'dark',
    primary: {
      main: colors.teal
    },
    secondary: {
      main: colors.pink
    },
    error: {
      main: colors.red
    },
    background: {
      paper: colors.bkgLight
    }
  // },
  // typography: {
  //   h1: {
  //     fontWeight: 600,
  //     fontSize: '2.5rem'
  //   }
  // },
  // overrides: {
  //   MuiTooltip: {
  //     tooltip: {
  //       fontSize: "1em",
  //       color: "white",
  //       backgroundColor: "rgba(0, 0, 0, 0.2)"
  //     }
  //   }
  }
};
