import React, { useEffect } from 'react';
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { colors } from "../../constants/colors";
import { round } from '../../utils/utilityFunctions';

// MUI components
import { Grid, AppBar, Tabs, Tab } from "@material-ui/core";

// Chart options
import { waveOptions } from '../../constants/chartOptions/waveOptions';
import { tempOptions } from '../../constants/chartOptions/tempOptions';
import { domPeriodOptions } from '../../constants/chartOptions/domPeriodOptions';
import { avgPeriodOptions } from '../../constants/chartOptions/avgPeriodOptions';
import { directionOptions } from '../../constants/chartOptions/directionOptions';

// Component Imports
import Timeline from '../layout/Timeline';
import BuoyList from '../layout/BuoyList';
import BuoyMap from '../layout/BuoyMap';
import SpectralGraph from '../layout/SpectralGraph';
import Compass from '../layout/Compass';
import Test from '../helpers/Test';

// Actions
import {
  fetchBuoyData,
  changeBuoySelectView,
  fetchWindData,
  resendVerificaitonEmail,
  getUser,
  sendFlash
} from "../../actions"

const Main = (props) => {
  // console.log(props)

  // fetch default buoy data on launch
  useEffect(() => {
    // code to run on component mount
    if (!props.buoyData.standardMeteo) {
      // get initial data into app
      props.fetchBuoyData(props.view.buoyId, 'meteo', '5day')
      props.fetchBuoyData(props.view.buoyId, 'spec_combo', '5day')
      props.fetchWindData()
    }

    if (props.auth && props.auth.isAuthenticated) {
      props.getUser()
    }

    if (props.location.search === '?verified') {
      props.sendFlash('Thanks for verifying your email :)', 'success')
    }
  }, [])

  const useStyles = makeStyles({
    root: {

    },
    popupBar: {
      backgroundColor: colors.verifyEmail,
      display: 'flex',
      flex: '1 1 auto',
      padding: '10px',
      justifyContent: 'center'
    },
    topSection: {
      padding: '5px',
      maxHeight: '420px'
    },
    tile: {
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: '#343434',
      margin: '5px',
    },
    map: {
      flex: '2 1 auto',
    },
    compass: {
      flex: '0 2 auto',
      display: 'flex',
      justifyContent: 'center',
      // backgroundColor: 'red',
      // maintain aspect ratio
      // width: '30vw',
      // height: '30vw'
    },
    spectral: {
      flex: '2 1 auto',
    },
    svg: {
      margin: '10px',
    },
    tabBar: {
      backgroundColor: colors.bkgDark
    },
    summary: {
      padding: '20px',
      textAlign: 'left',
      '& h3': {
        color: '#b9b9b9',
        margin: 0,
        paddingRight: '20px',
        fontSize: '1.3em'
      },
      '& span': {
        fontSize: '1.2em',
        fontWeight: '500'
      },
      '& > :first-child': {
        marginBottom: '10px'
      }
    },
    pink: {
      color: colors.pink
    },
    blue: {
      color: colors.blue
    }
  });

  const handleResendEmail = e => {
    props.resendVerificaitonEmail()
  }

  const handleTabChange = (event, newValue) => {
    props.changeBuoySelectView(newValue)
  }

  let selectedTime = props.view.selectedTime
  let currentSwellData
  let currentWindData

  if (props.buoyData.wind) {
    let windData = props.buoyData.wind
    let dates = Object.keys(windData)
    let date = dates[0]
    currentWindData = windData[date]
  }

  if (selectedTime) {
    currentSwellData = props.buoyData.standardMeteo[selectedTime]
    // console.log(props.buoyData)
  }

  let borderWidth = 10
  let radius = 100 - (borderWidth/2)
  let strokeLengthPercent = 10
  let strokeLength = strokeLengthPercent * 2 * Math.PI * radius / 100

  const classes = useStyles();

  return(
    // App Container
    <Grid container className={classes.root}>
      {/* Display warning if user email address isn't verified */}
      {'isVerified' in props.user && !props.user.isVerified &&
        <div className={classes.popupBar}>
          Please check your inbox and verify your email address | &nbsp;
          <Link
            to='#'
            onClick={() => handleResendEmail()}>
            Resend
          </Link>
        </div>
      }

      {/* Top Section */}
      <Grid item container wrap='nowrap' className={classes.topSection}>

        {/* buoy Selection */}
        <Grid item xs={4} className={`${classes.tile} ${classes.map}`}>

          <AppBar position="static" className={classes.tabBar}>
            <Tabs
              value={props.view.buoyView}
              onChange={handleTabChange}
              variant="fullWidth"
              // indicatorColor="secondary"
              indicatorColor="primary"
              // textColor="primary"
            >
              <Tab label="Map" value='map' />
              <Tab label="List" value='list' />
            </Tabs>
          </AppBar>
          { props.view.buoyView === 'list' && <BuoyList /> }
          { props.view.buoyView === 'map' && <BuoyMap /> }

        </Grid>

        {/* WAVE VISUALIZATION */}
        <Grid item container xs={4} wrap='nowrap' className={`${classes.tile} ${classes.compass}`}>
          <Compass />
        </Grid>

        {/* SPECTRAL GRAPH */}
        <Grid item container xs={4} wrap='nowrap' className={`${classes.tile} ${classes.spectral}`}>

          {/* Current Data */}
          <Grid item container direction='column' className={classes.summary}>

            {/* SWELLS */}
            <Grid item container wrap='nowrap'>
              <Grid item container xs>
                <h3>Swell:</h3>
              </Grid>
              <Grid item container xs={12} direction='column'>
                <Grid item container className={classes.pink}>
                  <span>
                    {
                      currentSwellData &&
                      `${round(currentSwellData.waveHeight, 1)}' @ ${currentSwellData.domPeriod}s (${currentSwellData.meanWaveDir}°)`
                    }
                  </span>
                </Grid>
              </Grid>
            </Grid>

            {/* WIND */}
            <Grid item container wrap='nowrap'>
              <Grid item container xs>
                <h3>Wind:</h3>
              </Grid>
              <Grid item container xs={12} className={classes.blue}>
                {/* <span>
                  {
                    currentWindData &&
                    `${round(currentWindData.windSpeed, 1)}kts [${round(currentWindData.windGust, 1)}kts] (${currentWindData.windDir}°)`
                  }
                </span> */}
              </Grid>
            </Grid>
          </Grid>

          <Grid container item xs={12}>
            <SpectralGraph />
          </Grid>

        </Grid>

      </Grid>


      {/* TIMELINES */}
      {props.buoyData.standardMeteo &&
        <Grid container direction='column'>
          <Timeline dataType='waveHeight' chartOptions={waveOptions} />
          <Timeline dataType='waterTemp' chartOptions={tempOptions} />
          <Timeline dataType='meanWaveDir' chartOptions={directionOptions} />
          <Timeline dataType='domPeriod' chartOptions={domPeriodOptions} />
          <Timeline dataType='avgPeriod' chartOptions={avgPeriodOptions} />
        </Grid>
      }

      <Test />

    </Grid>
  )
}

const mapDispatchToProps = {
  fetchBuoyData,
  changeBuoySelectView,
  fetchWindData,
  getUser,
  resendVerificaitonEmail,
  sendFlash
};

const mapStateToProps = state => {
  return {
    auth: state.auth,
    user: state.user,
    view: state.view,
    buoyData: state.buoyData
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Main);
