import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";

// My Components
import Centered from "../layout/Centered";

// MUI Imports
import { makeStyles } from '@material-ui/core/styles';
import { TextField, Button, Typography } from '@material-ui/core';

import ArrowBackIos from '@material-ui/icons/ArrowBackIos';

// Actions
import { loginUser } from "../../actions/index";

const useStyles = makeStyles({
  root: {},
  buttonBox: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '20px'
  }
});

const Login = (props) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const { isAuthenticated } = props.auth
  const errors = props.errors

  // If logged in and user navigates to Login page, should redirect them to dashboard
  useEffect(() => {
    if (isAuthenticated) props.history.push("/");
  }, [isAuthenticated]);

  const handleEmailChange = e => {
    setEmail(e.target.value)
  };

  const handlePasswordChange = e => {
    setPassword(e.target.value)
  };

  const handleSubmit = e => {
    e.preventDefault();

    const userData = {
      email,
      password,
    };

    props.loginUser(userData);
  };

  const classes = useStyles();

  return(
    <Centered maxWidth='300px'>
      <Button component={Link} to="/" startIcon={<ArrowBackIos />}>
        Home
      </Button>

      <Typography variant="h4">
        Login Below
      </Typography>
      <p>
        Don't have an account? <Link to="/register">Sign Up</Link>
      </p>
      <p>
        <Link to="/forgot">Forgot your password? </Link>
      </p>

      <form noValidate autoComplete="off" onSubmit={handleSubmit}>
        <TextField id="email" label="Email" fullWidth
          onChange={handleEmailChange}
          value={email}
          error={errors.email || errors.emailnotfound ? true : false}
          helperText={errors.email || errors.emailnotfound}
          type="email"
        />
        <TextField id="password" label="Password" fullWidth
          onChange={handlePasswordChange}
          value={password}
          error={errors.password || errors.passwordincorrect ? true : false}
          helperText={errors.password || errors.passwordincorrect}
          type="password"
        />
        <div className={classes.buttonBox}>
          <Button type="submit" variant="contained" color="primary">
            Login
          </Button>
        </div>
      </form>
    </Centered>
  )
}

Login.propTypes = {
  loginUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
};

const mapDispatchToProps = {
  loginUser
};

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
