import { setAuthStatus } from "../actions/index";
import setAuthToken from "./setAuthToken";
import jwt_decode from "jwt-decode";

// Performs all the necessary steps to log a user in on the front-end
const handleAuth = (token, dispatch) => {

  // Set token to localStorage
  localStorage.setItem("jwtToken", token);

  // Set token to Auth header
  setAuthToken(token);

  // Decode token to get user data
  const decoded = jwt_decode(token);

  // Set current user in redux store
  dispatch(setAuthStatus(decoded));
}

export default handleAuth;
