import React from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";

import { colors } from "../../constants/colors";

// Component Imports
import Wave from './Wave';

const Compass = (props) => {
  // console.log(props)

  const useStyles = makeStyles({
    root: {},
    svg: {
      margin: '10px',
    },
    marks: {
      transform: 'translate(100px, 100px)',
      strokeWidth: '1',
      stroke: 'rgba(122, 122, 122, 1)',
      strokeLinecap: 'round',
    },
    wind: {
      transition: 'transform 1s ease'
    },
    text: {
      fill: 'rgb(255, 255, 255)',
      fontSize: '0.8em',
      textAnchor: 'middle',
      dominantBaseline: 'middle'
    }
  });

  const classes = useStyles();

  let selectedTime = props.view.selectedTime
  let currentSwellData

  if (selectedTime && props.buoyData.standardMeteo) {
    currentSwellData = props.buoyData.standardMeteo[selectedTime]
  }

  let windStrokeWidth = 3
  let windRadius = 100 - (windStrokeWidth/2)
  let windWidthPercent = 10
  let windStrokeLength = windWidthPercent * 2 * Math.PI * windRadius / 100
  let windStrokeOffset = -90-(360 * windWidthPercent/100/2)
  let windDirection = 0

  if (props.buoyData.wind) {
    let windData = props.buoyData.wind
    let dates = Object.keys(windData)
    let date = dates[dates.length - 1]
    if (windData[date]) {
      windDirection = windData[date].windDir
    }
  }

  const Tick = (props) => {
    const { deg, length } = props
    return (
      <line x1={95-length} y1="0" x2="95" y2="0" transform={`rotate(${deg})`} />
    )
  }

  let majorTickDegrees = []
  for (var i = 0; i < 360; i+= 45) {
    majorTickDegrees.push(i)
  }

  let minorTickDegrees = []
  for (var k = 0; k < 360; k+= 15) {
    minorTickDegrees.push(k)
  }

  return(
    <svg viewBox="0 0 200 200" className={classes.svg}>

      {/* Main viewport Circle */}
      <circle cx="100" cy="100" r="100" fill='rgba(0, 0, 0, 0.1)' />

      {/* Wind Indicator */}
      <circle cx="100" cy="100" r={windRadius} className={classes.wind}
        fill='none'
        stroke={colors.blue}
        strokeWidth={windStrokeWidth}
        strokeDasharray={[windStrokeLength, 2 * Math.PI * windRadius]}
        transform={`rotate(${windDirection + windStrokeOffset} 100 100)`}
      />

      {/* Drection Labels */}
      <text x="50%" y="40" className={classes.text}>N</text>
      <text x="50%" y="160" className={classes.text}>S</text>
      <text x="40" y="50%" className={classes.text}>W</text>
      <text x="160" y="50%" className={classes.text}>E</text>


      {/* Sine Waves */}

      {/* Demo Wave */}
      <Wave
        color={colors.orange}
        thickness={3}
        speed={20}
        height={2}
        period={8}
        direction={320}
      />

      {/* Show waves if we have both a currently selected time and swell data */}
      { selectedTime && currentSwellData &&
        <Wave
          color={colors.pink}
          thickness={2}
          speed={20}
          height={currentSwellData.waveHeight}
          period={currentSwellData.domPeriod}
          direction={currentSwellData.meanWaveDir}
        />
      }

      <g className={classes.marks}>
        {majorTickDegrees.map((deg, index) => {
          return <Tick key={index} deg={deg} length={5} />
        })}

        {minorTickDegrees.map((deg, index) => {
          return <Tick key={index} deg={deg} length={0} />
        })}
      </g>

      {/* Central Hub */}
      <circle cx="100" cy="100" r="6" fill='rgb(59, 57, 57)' />
    </svg>
  )
}

const mapStateToProps = state => {
  return {
    view: state.view,
    buoyData: state.buoyData
  };
};

export default connect(mapStateToProps)(Compass);
