import { combineReducers } from "redux";
import authReducer from "./authReducer";
import userReducer from "./userReducer";
import allUsersReducer from "./allUsersReducer";
import errorReducer from "./errorReducer";
import flashReducer from "./flashReducer";

import buoyDataReducer from "./buoyDataReducer";
import viewReducer from "./viewReducer";
import windDataReducer from "./windDataReducer";


export default combineReducers({
  auth: authReducer,
  user: userReducer,
  flash: flashReducer,
  errors: errorReducer,
  allUsers: allUsersReducer,

  buoyData: buoyDataReducer,
  windData: windDataReducer,
  view: viewReducer
});
