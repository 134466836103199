// import React, { Component } from "react";
import React, { useState, useEffect } from "react";
import { Link, withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";

// My Components
import Centered from "../layout/Centered";

// MUI Imports
import { makeStyles } from '@material-ui/core/styles';
import { TextField, Button, Typography } from '@material-ui/core';

import ArrowBackIos from '@material-ui/icons/ArrowBackIos';

// Actions
import { registerUser } from "../../actions/index";

const useStyles = makeStyles({
  root: {},
  buttonBox: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '20px'
  }
});

const Register = (props) => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [password2, setPassword2] = useState('');

  const { isAuthenticated } = props.auth
  const errors = props.errors

  // If logged in and user navigates to Login page, should redirect them to dashboard
  useEffect(() => {
    if (isAuthenticated) props.history.push("/");
  }, [isAuthenticated]);

  const handleSubmit = e => {
    e.preventDefault();

    const newUser = {
      firstName,
      lastName,
      email,
      password,
      password2
    };

    props.registerUser(newUser, props.history); // passing history lets us redirect to new page
  };

  const classes = useStyles();


  return(
    <Centered maxWidth='400px'>
      <Button component={Link} to="/" startIcon={<ArrowBackIos />}>
        Home
      </Button>

      <Typography variant="h4">
        Sign Up
      </Typography>

      <p>
        Already have an account? <Link to="/login">Log in</Link>
      </p>

      <form noValidate autoComplete="off" onSubmit={handleSubmit}>
        <TextField id="firstName" label="First Name" fullWidth
          onChange={e => setFirstName(e.target.value)}
          value={firstName}
          error={errors.firstName ? true : false}
          helperText={errors.firstName}
          type="text"
        />
        <TextField id="lastName" label="Last Name" fullWidth
          onChange={e => setLastName(e.target.value)}
          value={lastName}
          error={errors.lastName ? true : false}
          helperText={errors.lastName}
          type="text"
        />
        <TextField id="email" label="Email" fullWidth
          onChange={e => setEmail(e.target.value)}
          value={email}
          error={errors.email ? true : false}
          helperText={errors.email}
          type="email"
        />
        <TextField id="password" label="Password" fullWidth
          onChange={e => setPassword(e.target.value)}
          value={password}
          error={errors.password ? true : false}
          helperText={errors.password}
          type="password"
        />
        <TextField id="password2" label="Confirm Password" fullWidth
          onChange={e => setPassword2(e.target.value)}
          value={password2}
          error={errors.password2 ? true : false}
          helperText={errors.password2}
          type="password"
        />

        <div className={classes.buttonBox}>
          <Button type="submit" variant="contained" color="primary">
            Sign Up
          </Button>
        </div>
      </form>
    </Centered>
  )
}

Register.propTypes = {
  registerUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
};

const mapDispatchToProps = {
  registerUser
};

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Register));
