import setAuthToken from "./setAuthToken";
import jwt_decode from "jwt-decode";
import store from "../store";

// Actions
import { setAuthStatus, logoutUser } from "../actions/index";

// Check for token to keep user logged in
const checkAuthToken = () => {
  // Check for token to keep user logged in
  if (localStorage.jwtToken) {

    // Set auth token header auth
    const token = localStorage.jwtToken;
    setAuthToken(token);

    // Decode token and get user info and exp
    const decoded = jwt_decode(token);

    // Set user and isAuthenticated
    store.dispatch(setAuthStatus(decoded));

    // Check for expired token
    const currentTime = Date.now() / 1000; // to get in milliseconds
    if (decoded.exp < currentTime) {
      // Logout user
      store.dispatch(logoutUser());

      // Redirect to login
      window.location.href = "./login";
    }
  }
}

export default checkAuthToken;
