import React from 'react';
import { render } from 'react-dom';
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { buoyDataTypes } from "../../constants/buoyDataTypes";

// Icons
import BuoyIcon from '@material-ui/icons/LocationOn';
// import RangeIcon from '@material-ui/icons/Timeline';

// MUI components
import Grid from "@material-ui/core/Grid";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

// Actions
import {
  // changeBuoy,
  // fetchBuoyData,
  updateBuoy
} from "../../actions"

const BuoyList = (props) => {
  console.log('buoyList', props)

  const useStyles = makeStyles({
    root: {
      // backgroundColor: 'red',
      overflow: 'auto'
    }
  });

  const classes = useStyles();

  let buoys = props.buoyData.buoys.byId

  return(
    <List classes={{ root: classes.root }}>
      {Object.keys(buoys).map((buoyId) => {
        let buoy = buoys[buoyId]
        return (
          <Buoy
            key={buoy.id}
            name={buoy.name}
            id={buoy.id}
            selected={props.view.buoyId === buoy.id} {...props}
          />
        )
      })}
    </List>
  )
}

const Buoy = (props) => {

  const handleClick = () => {
    // console.log('click!')
    // props.changeBuoy(props.id)
    // props.fetchBuoyData(props.id, 'meteo', props.view.range)
    props.updateBuoy(props.id)
    // fetchBuoyData(props.id, 'spectral')
  }

  const useStyles = makeStyles({
    root: {
      cursor: 'pointer',
      padding: '20px',
      color: 'white',
      '&:hover': {
        backgroundColor: 'rgba(255, 255, 255, 0.1)'
      }
    }
  });

  const classes = useStyles();

  return (
    <ListItem
      button
      onClick={() => handleClick()}
      selected={props.selected}
      >
      <ListItemIcon>
        <BuoyIcon />
      </ListItemIcon>
      <ListItemText
        primary={props.name}
        secondary={props.id}
      />
    </ListItem>
  )
}

const mapDispatchToProps = {
  // changeBuoy,
  // fetchBuoyData,
  updateBuoy
};

const mapStateToProps = state => {
  return {
    view: state.view,
    buoyData: state.buoyData
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BuoyList);
