export const domPeriodOptions = {
 chart: {
   type: 'line',
   zoomType: 'x'
 },
 tooltip: {
   dateTimeLabelFormats: {
     day: '%B %e',
     hour: '%A %B %e %l:%M%P',
     minute: '%A %B %e %l:%M%P'
   },
   headerFormat: '<span style="font-size: 14px">{point.key}</span><br/>',
   style: {
     fontSize: '16px'
   },
   valueDecimals: 1,
   // valuePrefix: '$',
   // valueSuffix: 's'
 },
  xAxis: {
    type: 'datetime',
    dateTimeLabelFormats: {
      // day: '%b %e',
      day: '%a %e',
      // hour: '%I:%M %p',
      hour: '%l:%M%P',
      minute: '%l:%M%P'
    }
  },
  yAxis: {
    title: {
      text: "Seconds"
    },
  },
  credits: {
    enabled: false
  },
  legend: {
    enabled: false
  },
  title: {
    text: "Dominant Period"
  },
  time: {
    useUTC: false
  },
  series: [
    {
      type: 'area',
      // name: buoyDataTypes[props.dataType].name,
      connectedNulls: true,
      // data: chartData
    }
  ]
}
