import React from 'react';
import { makeStyles } from "@material-ui/core/styles";

// A simple component for centering its contents both horizontally and vertically
const Centered = (props) => {

  const useStyles = makeStyles({
    root: {
      // backgroundColor: 'teal',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    centered: {
      marginTop: '20px',
      padding: '20px',
      maxWidth: props.maxWidth || 'none'
    }
  });

  const classes = useStyles();

  return(
    <div className={classes.root}>
      <div className={classes.centered}>
        {props.children}
      </div>
    </div>
  )
}

export default Centered;
