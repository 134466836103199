import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import checkAuthToken from "./utils/checkAuthToken";
import CssBaseline from '@material-ui/core/CssBaseline';

import { Provider } from "react-redux";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import { theme } from "./constants/theme";
import store from "./store";

// My Components
import Header from "./components/layout/Header";
import SignUp from "./components/auth/SignUp";
import Login from "./components/auth/Login";
import ForgotPassword from "./components/auth/ForgotPassword";
import ResetPassword from "./components/auth/ResetPassword";
import Flash from "./components/layout/Flash";

import Dashboard from "./components/main/Dashboard";
import Profile from "./components/main/Profile";
import Admin from "./components/main/Admin";

import ScrollToTop from "./components/helpers/ScrollToTop";

import PrivateRoute from "./components/helpers/PrivateRoute";
import AdminRoute from "./components/helpers/AdminRoute";

// Check for token to keep user logged in
checkAuthToken()

const App = (props) => {
  return (
    <MuiThemeProvider theme={createMuiTheme(theme)}>
      <CssBaseline />
      <Provider store={store}>
        <Router>
          <ScrollToTop />
          <Header />
          <Route exact path="/" component={Dashboard} />
          <Route exact path="/register" component={SignUp} />
          <Route exact path="/login" component={Login} />
          <Route exact path="/forgot" component={ForgotPassword} />
          <Route exact path="/reset/:token" component={ResetPassword} />
          <Switch>
            <PrivateRoute exact path="/profile" component={Profile} />
            <AdminRoute exact path="/admin" component={Admin} />
          </Switch>
          <Flash />
        </Router>
      </Provider>
    </MuiThemeProvider>
  );
}

export default App;
