import axios from "axios";
import { batch } from "react-redux";

import {
  requestData,
  fetchBuoyData
} from "../actions";

export const LOAD_USER_DATA = "LOAD_USER_DATA";
export const EXPUNGE_USER_DATA = "EXPUNGE_USER_DATA";
export const SET_USER_LOADING = "SET_USER_LOADING";

export const LOAD_ALL_USER_DATA = "LOAD_ALL_USER_DATA";
export const SET_ALL_USERS_LOADING = "SET_ALL_USERS_LOADING";

export const CHANGE_BUOY = "CHANGE_BUOY";
export const CHANGE_RANGE = "CHANGE_RANGE";
export const UPDATE_SELECTED_TIME = "UPDATE_SELECTED_TIME";
export const CHANGE_BUOY_SELECT_VIEW = "CHANGE_BUOY_SELECT_VIEW";

// Fetch user data
export const getUser = () => dispatch => {
  dispatch(setUserLoading())
  axios.get("/api/users/current")
    .then(
      response => {
        dispatch(loadUserData(response.data));
      },
      error => {
        console.log(error)
      }
    )
};

// Load user data -> redux state
export const loadUserData = (userData) => {
  return {
    type: LOAD_USER_DATA,
    userData: userData
  };
};

// Remove all user data on logout
export const expungeUserData = () => {
  return {
    type: EXPUNGE_USER_DATA,
  };
};

// Change user data loading status to true
export const setUserLoading = () => {
  return {
    type: SET_USER_LOADING
  };
};


//////////////////
// ALL USERS  ////
/////////////////

// Fetch all user data
export const getAllUsers = () => dispatch => {
  dispatch(setAllUsersLoading())
  console.log('Fetching all users...')
  axios.get("/api/users/")
    .then(
      response => {
        dispatch(loadAllUserData(response.data));
      },
      error => {
        console.log(error)
      }
    )
};

export const setAllUsersLoading = () => {
  return {
    type: SET_ALL_USERS_LOADING
  };
};

export const loadAllUserData = (data) => {
  return {
    type: LOAD_ALL_USER_DATA,
    data: data
  };
};


///////////////////
// USER ACTIONS  //
//////////////////

export function changeBuoy(buoyId) {
  return {
    type: CHANGE_BUOY,
    buoyId: buoyId
  }
}

export function changeRange(range) {
  return {
    type: CHANGE_RANGE,
    range: range
  }
}

export function updateSelectedTime(newTime) {
  return {
    type: UPDATE_SELECTED_TIME,
    newTime: newTime
  }
}

export function changeBuoySelectView(newView) {
  return {
    type: CHANGE_BUOY_SELECT_VIEW,
    newView: newView
  }
}

export function updateBuoy(buoyId) {
  return (dispatch, getState) => {
    const state = getState(); // access to application state

    // only dispatch actions if buoyId actually changes
    if (buoyId !== state.view.buoyId) {
      batch(() => {
        // toggle loading indicator on (already happening in fetchBuoyData)
        // dispatch(requestData())

        // change active buoy
        dispatch(changeBuoy(buoyId))

        // get buoy data
        dispatch(fetchBuoyData(buoyId, 'meteo', state.view.range))

        // get spectral data
        dispatch(fetchBuoyData(buoyId, 'spectral', state.view.range))

        // get tide data

        // when finished, turn off loading indicator
      });
    }
  };
}

export function updateRange(range) {
  return (dispatch, getState) => {
    const state = getState(); // access to application state
    if (range !== state.view.range) {
      batch(() => {

        // change range
        dispatch(changeRange(range))

        // get buoy data
        dispatch(fetchBuoyData(state.view.buoyId, 'meteo', range))

        // get spectral data
        dispatch(fetchBuoyData(state.view.buoyId, 'spectral', range)) // dont update spectral for now because dont have accessw to 45 day spectral

        // get tide data

        // when finished, turn off loading indicator
      });
    }
  };
}
