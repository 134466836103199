import React from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";


import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';



const Test = (props) => {

  const useStyles = makeStyles({
    root: {}
  });

  const classes = useStyles();

  return(
    <div className={classes.root}>

    </div>
  )
}

const mapStateToProps = state => {
  return {
    // templateData: state.templateData.test
  };
};

export default connect(mapStateToProps)(Test);
