import { SET_AUTH_STATUS } from "../actions/index";

const isEmpty = require("is-empty");

const initialState = {
  isAuthenticated: false,
  // user: {},
  // loading: false
};

export default function(state = initialState, action) {
  switch (action.type) {
    case SET_AUTH_STATUS:
      return {
        ...state,
        isAuthenticated: !isEmpty(action.payload),
        // user: action.payload // this is the decoded jwt - do we need this? dont think so...
      };
    // case USER_LOADING:
    //   return {
    //     ...state,
    //     loading: true
    //   };
    default:
      return state;
  }
}
