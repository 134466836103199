import {
  LOAD_USER_DATA,
  EXPUNGE_USER_DATA,
  SET_USER_LOADING
} from "../actions/index";

const initialState = {};

export default function(state = initialState, action) {
  switch (action.type) {
    case LOAD_USER_DATA:
      let userData = action.userData
      return {
        ...state,
        ...userData,
        loading: false
      };
    case EXPUNGE_USER_DATA:
      return {};
    case SET_USER_LOADING:
      return {
        ...state,
        loading: true
      };
    default:
      return state;
  }
}
