import {
  CHANGE_BUOY,
  REQUEST_DATA,
  RECEIVE_DATA,
  RECEIVE_WIND_DATA,
  CHANGE_RANGE,
  CHANGE_BUOY_SELECT_VIEW,
  UPDATE_SELECTED_TIME
} from '../actions'

const initialState = {
  buoyId: 46225,
  buoyView: 'map', // 'list' or 'map'
  range: '5day',
  selectedTime: null,
  requestingData: false
}

export default function(state = initialState, action) {
  switch (action.type) {
    case CHANGE_BUOY:
      return {
        ...state,
        buoyId: action.buoyId
      };

    case CHANGE_RANGE:
      return {
        ...state,
        range: action.range
      };

    case REQUEST_DATA:
      return {
        ...state,
        requestingData: true
      };

    case RECEIVE_DATA:
      if (action.dataType === 'meteo' && !state.selectedTime) {
        // get last time with wave data

        let buoyData = action.data
        let dates = Object.keys(buoyData)
        let lastDate = dates[0]

        return {
          ...state,
          requestingData: false, // lets wait for spectral data to come in first
          selectedTime: lastDate, // initialize selectedTime to most recent data point
        }
      } else {
        return {
          ...state,
          requestingData: false,
        }
      }

    case RECEIVE_WIND_DATA:
      return {
        ...state,
        requestingData: false,
      }

    case UPDATE_SELECTED_TIME:
      return {
        ...state,
        selectedTime: action.newTime
      };

    case CHANGE_BUOY_SELECT_VIEW:
      return {
        ...state,
        buoyView: action.newView
      };

    default:
      return state;
  }
}
