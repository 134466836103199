export const waveOptions = {
 chart: {
   type: 'line',
   zoomType: 'x'
 },
 tooltip: {
   dateTimeLabelFormats: {
     day: '%B %e',
     hour: '%A %B %e %l:%M%P',
     minute: '%A %B %e %l:%M%P'
   },
   headerFormat: '<span style="font-size: 14px">{point.key}</span><br/>',
   style: {
     fontSize: '16px'
   },
   valueDecimals: 1,
   // valuePrefix: '$',
   valueSuffix: ' ft'
 },
  xAxis: {
    type: 'datetime',
    dateTimeLabelFormats: {
      day: '%a %e',
      hour: '%l:%M%P',
      minute: '%l:%M%P'
    }
  },
  yAxis: {
    title: {
      text: "Feet"
    },
  },
  credits: {
    enabled: false
  },
  legend: {
    enabled: false
  },
  title: {
    text: "Significant Wave Height"
  },
  time: {
    useUTC: false
  },
  series: [
    {
      type: 'areaspline',
      name: 'Significant Wave Height',
      connectedNulls: true,
      allowPointSelect: true
    }
  ]
}
