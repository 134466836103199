import React from 'react';
import { render } from 'react-dom';
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { buoyDataTypes } from "../../constants/buoyDataTypes";
import L from 'leaflet';
import { Map, TileLayer, Marker, Popup } from "react-leaflet";



// Icons

// MUI components


// Actions
import {
  updateBuoy
} from "../../actions"

const BuoyMap = (props) => {
  let { center, view, buoyData } = props
  if ( !center ) {
    center = [40.181948, -73.281130]
  }
  // console.log(props)
  // console.log(L)
  // var mymap = L.map('leafletMap').setView([51.505, -0.09], 13);

  const useStyles = makeStyles({
    root: {

    },
    map: {
      height: 'calc(100% - 50px)'
      // flex: '1 0 auto'
    }
  });

  const classes = useStyles();

  let buoys = props.buoyData.buoys.byId
  if (buoyData) {
    // console.log(buoyData)
    // console.log('view', view.buoyId)
    center = buoyData.buoys.byId[view.buoyId].location
    // console.log(center)
  }


  const handlBuoySelect = (buoyId) => {
      // console.log('click!', buoyId)
      props.updateBuoy(buoyId)
  }
  // console.log('icon', L.Icon.Default.prototype)

  // var buoyIcon = L.icon({
  //   // ...L.Icon.Default.prototype.options,
  //   iconUrl: 'https://unpkg.com/leaflet@1.6.0/dist/images/marker-icon-2x.png',
  //   // iconRetinaUrl: '../assets/pointerIcon.svg',
  //   // iconAnchor: [5, 55],
  //   // popupAnchor: [10, -44],
  //   // iconSize: [25, 55],
  //   // shadowUrl: '../assets/marker-shadow.png',
  //   // shadowSize: [68, 95],
  //   // shadowAnchor: [20, 92],
  // });

  return (
    <Map
      center={[center[0], -center[1]]}
      zoom={10}
      className={classes.map}
      attributionControl={false}
      scrollWheelZoom={false}
    >
      <TileLayer
        // attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'

        // DARK
        url = {
          `https://tiles.stadiamaps.com/tiles/alidade_smooth_dark/{z}/{x}/{y}{r}.png?api_key=8e261e23-9b2c-4e92-b553-ee83c63c147e`
        }
        // url="https://stamen-tiles-{s}.a.ssl.fastly.net/toner-background/{z}/{x}/{y}{r}.png"

        // LIGHT
        // url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}"
        // url="https://server.arcgisonline.com/ArcGIS/rest/services/Canvas/World_Light_Gray_Base/MapServer/tile/{z}/{y}/{x}"
        // url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png"


        // BATH
        // url="https://server.arcgisonline.com/ArcGIS/rest/services/Ocean_Basemap/MapServer/tile/{z}/{y}/{x}"
        // url="https://{s}.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}{r}.png"
        // url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}"
      />
      {Object.keys(buoys).map((buoyId) => {
        let buoy = buoys[buoyId]
        return (
          <Marker
            key={buoy.id}
            position={[buoy.location[0], -buoy.location[1]]}
            onClick={() => handlBuoySelect(buoy.id)}
            opacity={props.view.buoyId === buoy.id ? 1 : 0.3}
            // icon={buoyIcon} // custom icon
          >
            <Popup>
              {buoy.name} <br />
              {buoy.id}
            </Popup>
          </Marker>
          // <Buoy
          //   key={buoy.id}
          //   name={buoy.name}
          //   id={buoy.id}
          //   selected={props.view.buoyId === buoy.id} {...props}
          // />
        )
      })}

    </Map>
  )
}

const mapDispatchToProps = {
  updateBuoy
};

const mapStateToProps = state => {
  return {
    view: state.view,
    buoyData: state.buoyData
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BuoyMap);
