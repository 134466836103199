import {
  LOAD_ALL_USER_DATA,
  SET_ALL_USERS_LOADING
} from "../actions/index";

const initialState = {};

export default function(state = initialState, action) {
  switch (action.type) {
    case LOAD_ALL_USER_DATA:
      return {
        ...state,
        ...action.data,
        loading: false
      };
    case SET_ALL_USERS_LOADING:
      return {
        ...state,
        loading: true
      };
    default:
      return state;
  }
}
