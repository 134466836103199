import axios from "axios";
export const REQUEST_DATA = "REQUEST_DATA"; // show spinner state
export const RECEIVE_DATA = "RECEIVE_DATA"; // display data
export const RECEIVE_WIND_DATA = "RECEIVE_WIND_DATA";

export function requestData() {
  return {
    type: REQUEST_DATA
  };
}

export function receiveData(buoyId, dataType, range, data) {
  return {
    type: RECEIVE_DATA,
    buoyId: buoyId,
    dataType: dataType,
    range: range,
    data: data
  };
}

export function receiveWindData(data) {
  return {
    type: RECEIVE_WIND_DATA,
    data: data
  };
}

export function fetchWindData() {
  return function(dispatch) {

    // first check and see if already have data

    dispatch(requestData())
    console.log('fetching wind data...')
    let url = '/wind/'

    axios.get(url)
      .then(
        response => dispatch(receiveWindData(response.data)),
        error => {
          console.log('we have an error')
          console.log(error)
        }
      )
  };
}

export function fetchBuoyData(buoyId, dataType, range='5day') {
  return function(dispatch) {

    // first check and see if already have data

    dispatch(requestData())
    // range should be 5 or 45 (defaults to 5)
    console.log(`fetching ${dataType} data for buoy ${buoyId}...`)
    let url = `/buoys/${buoyId}/${dataType}/${range}`

    axios.get(url)
      .then(
        response => {
          console.log('response: ', response.data)
          dispatch(receiveData(buoyId, dataType, range, response.data))
        },
        error => {
          console.log('we have an error')
          console.log(error)
        }
      )
  };
}

// export function example(info) {
//   return (dispatch, getState) => {
//     const state = getState(); // access to application state
//     let newLoc = {
//       locationId: info.locationId,
//       locationName: info.locationName,
//       maps: info.maps
//     };
//     batch(() => {
//       dispatch(fetchAddNewLocation(newLoc)); //add the new location to server
//       dispatch(updateLocationList(newLoc)); //update the location reducer, update the protocol on studyInfo
//       dispatch(toggleAddNewLocationDialog()); //close the add new location dialog
//     });
//     dispatch(calculateTotalSamplesNeeded()); //update samples required and study progress
//   };
// }
