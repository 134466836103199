import React from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";

import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

import { spectralOptions } from '../../constants/chartOptions/spectralOptions';

import { round } from '../../utils/utilityFunctions';
import { calcSigWaveHeight } from '../../utils/utilityFunctions';
import { findPeaks } from '../../utils/findPeaksAlgos/findPeaks';
// import { getD3Peaks } from '../../utils/findPeaksAlgos/d3Peaks';


const SpectralGraph = (props) => {

  const useStyles = makeStyles({
    root: {
      flexBasis: '100%',
      minWidth: 0,
    },
  });

  const classes = useStyles();

  let spectralChartOptions
  let spectralData = props.buoyData.specCombo
  let selectedTime = props.view.selectedTime
  let spectralReading

  // if combined spectral data is available and a time is selected
  if (spectralData && selectedTime) {
    // console.log('selectedTime', selectedTime)
    let spectralDates = Object.keys(spectralData)

    // if selected time is in frequency data
    if (spectralDates.includes(selectedTime)) {
      console.log('found an exact match!')
      spectralReading = spectralData[selectedTime]

    // otherwise find next closest time in freq data
    } else {
      console.log('no matches found')
      // console.log('all spectral dates', spectralDates)
      let newDate = spectralDates.sort().find(e => e > selectedTime)
      if (newDate) {
        console.log('found next available date', newDate)
      } else {
        newDate = spectralDates[0]
        console.log('no further dates available, using last possible date', newDate)
      }
      spectralReading = spectralData[newDate]
    }
    // console.log('spectralReading:', spectralReading)

    // console.log('spectralReading', spectralData[date])
    // console.log('significant wave height - all freqs', calcSigWaveHeight(spectralReading, '0.025', '0.580') * 3.28084 + ' ft')
    // console.log('significant wave height', calcSigWaveHeight(spectralReading, '0.150'))
    // console.log('significant wave height', calcSigWaveHeight(spectralReading, '0.101', '0.200'))
    // console.log('significant wave height', calcSigWaveHeight(spectralReading, '0.140', '0.160'))
    // console.log(spectralReading, Object.keys(spectralReading).length)

    // Prep data for chart
    // Data used to graph energy [period, energy]
    let energyData = Object.keys(spectralReading).reverse() // reverse it because we're graphing period, not Hz
      .map((freq) => {
        let period = spectralReading[freq].period

        // ENERGY
        let energy = spectralReading[freq].energy
        // let bandwidth = spectralReading[freq].bandwidth
        return [round(period, 1), energy]
      })

      const bandsPeriod = [
        [2, 6],
        [6, 8],
        [8, 10],
        [10, 12],
        [12, 14],
        [14, 16],
        [16, 18],
        [18, 22],
        [22]
      ]

      const bands = [
        [0.25, 0.17],
        [0.17, 0.12],
        [0.12, 0.101],
        [0.101, 0.085],
        [0.085, 0.070],
        [0.070, 0.060],
        [0.060, 0.055],
        [0.055, 0.045],
        [0.045, 0.040]
      ]


      console.log('calculating nine bands... \n')
      let nineBandHeightData = bands.map(band => {
        // console.log('spectralReading', spectralReading)
        const startFreq = band[0]
        const endFreq = band[1]
        const avgFreq = (startFreq + endFreq) / 2
        const avgPeriod = 1 / avgFreq
        const Hs = calcSigWaveHeight(spectralReading, endFreq, startFreq) * 3.28084
        return {
          startFreq: startFreq,
          endFreq: endFreq,
          avgFreq: avgFreq,
          avgPeriod: avgPeriod,
          Hs: Hs,
          HsMeters: Hs / 3.28084
        }
      })
      // console.log('nineBandHeightData:', nineBandHeightData)

      const heightData = nineBandHeightData.map(band => [1/band.avgFreq, band.Hs])


    // const peakData = getD3Peaks(spectralReading)

    // Prep data for peak detection
    // array of energy values
    let signal = Object.keys(spectralReading).reverse() // reverse it because we're graphing period, not Hz
      .map((freq) => {
        let energy = spectralReading[freq].energy
        return energy
      })

    // Data used to graph peaks [period, energy]
    let peakData = findPeaks(signal, 0.3)
      .map((peak) => {
        let energy = peak.value
        let period = energyData[peak.index][0]
        return [period, energy]
      })


    // Data used to graph spectral swell direction [period, direction]
    let directionData = Object.keys(spectralReading).reverse() // reverse it because we're graphing period, not Hz
      .map((freq) => {
        let period = spectralReading[freq].period

        // ENERGY
        // let bandwidth = spectralReading[freq].bandwidth
        let direction = spectralReading[freq].alpha1
        return [round(period, 1), direction]
      })

    spectralChartOptions = {
      ...spectralOptions,
      series: [
        {
          ...spectralOptions.series[0],
          data: energyData
        },
        {
          ...spectralOptions.series[1],
          data: peakData
        },
        {
          ...spectralOptions.series[2],
          data: directionData
        },
        // {
        //   ...spectralOptions.series[3],
        //   data: heightData
        // }
      ]
    }
  }

  return(
    <div className={classes.root}>
      <HighchartsReact
        highcharts={Highcharts}
        options={spectralChartOptions}
      />
    </div>
  )
}

const mapStateToProps = state => {
  return {
    buoyData: state.buoyData,
    view: state.view
  };
};

export default connect(mapStateToProps)(SpectralGraph);
