import axios from "axios";
import setAuthToken from "../utils/setAuthToken";
import handleAuth from "../utils/handleAuth";
import { expungeUserData, sendFlash } from "../actions/index";
import { batch } from "react-redux";

export const GET_ERRORS = "GET_ERRORS";
export const SET_AUTH_STATUS = "SET_AUTH_STATUS";

// Register User
export const registerUser = (userData, history) => dispatch => {
  axios.post("/api/auth/register", userData)
    .then(res => {
      const { token } = res.data
      handleAuth(token, dispatch) // log user in
    })
    .catch(err =>
      dispatch(updateErrors(err))
    );
};

// Login - get user token
export const loginUser = userData => dispatch => {
  axios.post("/api/auth/login", userData)
    .then(res => {
      const { token } = res.data
      handleAuth(token, dispatch) // log user in
    })
    .catch(err =>
      dispatch(updateErrors(err))
    );
};

// request new email verificaiton email
export const resendVerificaitonEmail = () => (dispatch, getState) => {
  const { user } = getState();
  axios.get("/api/auth/resend")
    .then(
      res => {
        dispatch(sendFlash(`Email verification re-sent to ${user.email}`, 'success')) // update flash message
      },
      error => {
        console.log(error)
      }
    )
};

// Requset Password Reset
export const requestPasswordReset = (email) => dispatch => {
  axios.post("/api/auth/request-reset", { email: email })
    .then(res => {
      dispatch(sendFlash(`A password reset link has been sent to ${email}`, 'success')) // update flash message
    })
    .catch(err =>
      dispatch(updateErrors(err))
    );
};

// Reset Password
export const resetPassword = (userData) => dispatch => {
  // hit api enpoint for resetting password
  axios.post('/api/auth/reset', userData)
    .then(res => {
      const { token } = res.data
      handleAuth(token, dispatch) // log user in
      dispatch(sendFlash('Password Successfully Reset', 'success')) // update flash message
    })
    .catch(err =>
      batch(() => {
        dispatch(updateErrors(err))
        if (err.response.data.token) {
          dispatch(sendFlash('Password Reset Link Expired', 'warning')) // update flash message
        }
      })
    );
};

// Set logged in user
export const setAuthStatus = (decoded) => {
  return {
    type: SET_AUTH_STATUS,
    payload: decoded
  };
};

// Update Form Input Errors -> redux state
export const updateErrors = (err) => {
  return {
    type: GET_ERRORS,
    payload: err.response.data
  };
};

// Log user out
export const logoutUser = () => dispatch => {

  // Remove token from local storage
  localStorage.removeItem("jwtToken");

  // Remove auth header for future requests
  setAuthToken(false);


  batch(() => {
    // Update Redux state - Set current user to empty object {}
      // which will set isAuthenticated to false
    dispatch(setAuthStatus({}));

    // remove user data from redux
    dispatch(expungeUserData());
  })
};
