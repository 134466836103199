import round from '../../utils/utilityFunctions';

export const spectralOptions = {
 chart: {
   type: 'line',
   zoomType: 'x',
   height: '300px'
 },
 tooltip: {
   headerFormat: '<span style="font-size: 14px">Period: {point.key}s</span><br/>',
   crosshairs: true,
   shared: true,
   style: {
     fontSize: '16px'
   },
   valueDecimals: 1,
   // // valuePrefix: '$',
   // valueSuffix: ''
 },
  xAxis: {
    // type: 'datetime',
    title: {
      text: "period"
    },
    max: 25
  },
  yAxis: [
    {
      // Primary Axis
      // max: 4.5, // fixed max height
      title: {
        text: "Energy (m²/Hz)",
        style: {
          // color: Highcharts.getOptions().colors[2]
        }
      },
      labels: {
        format: '{value}',
      }
    },
    {
      // Secondary Axis
      title: {
        text: "Direction (deg)",
        enabled: false,
        style: {
          // color: Highcharts.getOptions().colors[2]
        }
      },
      // labels: {
      //   format: '{value}°'
      // },
      tickInterval: 45,
      labels: {
        formatter: function () {
          let deg = this.value
          let dir
          if (deg >= 0 && deg < 11.25) dir = 'N'
          else if (deg >= 11.25 && deg < 33.75) dir = 'NNE'
          else if (deg >= 33.75 && deg < 56.25) dir = 'NE'
          else if (deg >= 56.25 && deg < 78.75) dir = 'ENE'
          else if (deg >= 78.75 && deg < 101.25) dir = 'E'
          else if (deg === 135) dir = 'SE'
          else if (deg === 180) dir = 'S'
          else if (deg === 225) dir = 'SW'
          else if (deg === 270) dir = 'W'
          else if (deg === 315) dir = 'NW'
          else if (deg === 360) dir = 'N'
          return this.value + '° ' + dir;
        }
      },
      opposite: true
    },
    {
      // Third Axis
      title: {
        text: "Height (ft)",
        enabled: false,
        style: {
          // color: Highcharts.getOptions().colors[2]
        }
      },
      labels: {
        format: '{value} ft'
      },
      // opposite: true
    },
  ],
  credits: {
    enabled: false
  },
  legend: {
    enabled: false
  },
  title: {
    text: null //"Swell Spectrum"
  },
  time: {
    useUTC: false
  },
  series: [
    {
      name: 'Swell Energy',
      type: 'areaspline',
      connectedNulls: true,
      tooltip: {
        pointFormat: 'Energy: <b>{point.y}</b><br/>',
        valueSuffix: 'm²/Hz'
      }
    },
    {
      name: 'Peak',
      type: 'spline',
      lineWidth: 0,
      marker: {
        enabled: true,
        radius: 3,
        symbol: 'circle'
      },
      states: {
        hover: {
          lineWidthPlus: 0
        }
      },
      tooltip: {
        enabled: false
      }
    },
    {
      name: 'Swell Direction',
      type: 'spline',
      lineWidth: 1,
      yAxis: 1,
      color: "#e627de",
      marker: {
        radius: 3,
        symbol: 'circle'
      },
      tooltip: {
        pointFormat: 'Direction: <b>{point.y}°</b><br/>'
      }
    },
    {
      name: 'Height',
      type: 'column',
      yAxis: 2,
      color: "#ffffff",
      borderWidth: 0,
      opacity: 0.3,
      pointWidth: 10,
      tooltip: {
        pointFormat: 'Direction: <b>{point.y}ft</b><br/>'
      }
    }
  ]
}
