// My Algo
// data: array of data
// min: minimum value allowed
export const findPeaks = (data, min) => {
  let peaks = []

  // iterate over array
  for (let i = 0; i < data.length; i++) {
    // check if data point is greater than previous point and next point
    if (data[i] >= data[i-1] && data[i] >= data[i+1]) {
      peaks.push({
        index: i,
        value: data[i]
      })
    }
    peaks = peaks.filter(peak => peak.value >= min)
  }
  // console.log('Peaks Detected:', peaks)
  return peaks
}
