export const directionOptions = {
 chart: {
   type: 'line',
   zoomType: 'x'
 },
 tooltip: {
   dateTimeLabelFormats: {
     day: '%B %e',
     hour: '%A %B %e %l:%M%P',
     minute: '%A %B %e %l:%M%P'
   },
   headerFormat: '<span style="font-size: 14px">{point.key}</span><br/>',
   style: {
     fontSize: '16px'
   },
   valueDecimals: 1,
   // valuePrefix: '$',
   valueSuffix: '°'
 },
  xAxis: {
    type: 'datetime',
    dateTimeLabelFormats: {
      // day: '%b %e',
      day: '%a %e',
      // hour: '%I:%M %p',
      hour: '%l:%M%P',
      minute: '%l:%M%P'
    }
  },
  yAxis: {
    title: {
      text: "Direction"
    },
    min: 0,
    max: 360
  },
  credits: {
    enabled: false
  },
  legend: {
    enabled: false
  },
  title: {
    text: "Direction"
  },
  time: {
    useUTC: false
  },
  series: [
    {
      type: 'line',
      // name: buoyDataTypes[props.dataType].name,
      connectedNulls: true,
      // data: chartData
      zones: [
        // {
        //   color: {
        //     linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
        //     stops: [
        //       [0, '#eda935'], // top
        //       [1, '#2b908f'] // bottom
        //     ]
        //   },
        //   value: 360
        // },
        {
          color: '#eda935',
          value: 30
        },
        {
          color: '#2b908f',
          value: 160
        },
        {
          color: '#eda935',
          value: 180
        },
        {
          color: '#ed3535',
          value: 360
        }
      ]
    }
  ]
}
