import React from 'react';
import produce from "immer";
import { render } from 'react-dom';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import darkUnica from "highcharts/themes/dark-unica";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { buoyDataTypes } from "../../constants/buoyDataTypes";
import moment from "moment";

// MUI components
import Grid from "@material-ui/core/Grid";

// Actions
import {
  updateSelectedTime
} from "../../actions"

// apply dark unica theme to charts
darkUnica(Highcharts);

const getNearestX = (chart, event) => {
  console.log(chart, event)
}

const Timeline = (props) => {
  // console.log(props.buoyData)

  let chartData
  let standardMeteo
  let spectralCombo

  // let standardMeteo = props.buoyData.standardMeteo
  // let spectralCombo = props.buoyData.specCombo
  // console.log(spectralCombo)

  if (props.buoyData.standardMeteo) {
    standardMeteo = props.buoyData.standardMeteo
    // make array of all dates
    chartData = Object.keys(standardMeteo)
      // remove any null values from date array (they make gaps in the graph)
      .filter((date) => {
        return standardMeteo[date][props.dataType] ? true : false
      })
      .map((date) => {
        let y = standardMeteo[date][props.dataType]
        return [Number(date), y]
      })
      // make sure dates are in order
      .sort((a, b) => {
        return a[0] - b[0]
      })
  }

  if (props.buoyData.specCombo) {
    spectralCombo = props.buoyData.specCombo
    // console.log('specCombo', spectralCombo)
  }

  const useStyles = makeStyles({
    root: {
      margin: '10px'
    }
  });

  const classes = useStyles();

  // immutably update baseOptions with data
  // let chartOptions = produce(props.chartOptions, draftState => {
  //   draftState.series[0].data = chartData
  // })

  let chartOptions = {
    ...props.chartOptions,
    chart: {
      events: {
        // selection:
        // click: function(event) {
        //   getNearestX(this, event)
        //   // let x = event.xAxis[0].value
        //   // console.log(this, event)
        //   // console.log('click', x)
        // }
      }
    },
    xAxis: {
      ...props.chartOptions.xAxis,
      // minTickInterval: moment.duration(1, 'day').asMiliseconds(),
      plotBands: [
        {
          from: Date.UTC(2020, 5, 16, 0, 30),
          to: Date.UTC(2020, 5, 16, 9, 30),
          color: "rgba(0, 0, 0, 0.1)",
          zIndex: 3
        },
        {
          from: Date.UTC(2020, 5, 17, 0, 30),
          to: Date.UTC(2020, 5, 17, 9, 30),
          color: "rgba(0, 0, 0, 0.1)",
          zIndex: 3
        },
        {
          from: Date.UTC(2020, 5, 18, 0, 30),
          to: Date.UTC(2020, 5, 18, 9, 30),
          color: "rgba(0, 0, 0, 0.1)",
          zIndex: 3
        },
      ]
    },
    series: [
      {
        ...props.chartOptions.series[0],
        data: chartData,
        point: {
          events: {
            // mouseOver, click, select
            select: function (event) {
              console.log('point selection event')
              console.log('x: ', event.target.x)
              // if new time is in spectral combo data
              props.updateSelectedTime(event.target.x)
              // if (spectralCombo && spectralCombo[event.target.x]) {
              //   // console.log(event.target.x)
              //   console.log('date is in data!')
              //   props.updateSelectedTime(event.target.x)
              // } else {
              //   console.log('getting next closest date')
              //   // select next date
              //   // iterate over all dates
              //   let allDates = Object.keys(spectralCombo)
              //   // test for next date greater than selected date
              //   let nextDate = allDates.find((date) => {
              //     return date > event.target.x
              //   })
              //   props.updateSelectedTime(nextDate)
              // }
            }
          }
        }
      }
    ]
  }

  return(
    <Grid item xs>
      <div className={classes.root}>
        <HighchartsReact
          highcharts={Highcharts}
          options={chartOptions}
        />
      </div>
    </Grid>
  )
}

const mapDispatchToProps = {
  updateSelectedTime
};

const mapStateToProps = state => {
  return {
    buoyData: state.buoyData,
    standardMeteo: state.buoyData.standardMeteo,
    // chartOptions: state.chartOptions
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Timeline);
