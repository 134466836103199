export const colors = {
  teal: "#2b908f",
  orange: "#e6a527",
  pink: "#e627de",
  blue: "#2274d5",
  red: "#dd4e4e",

  bkgDark: "#343434",
  bkgLight: "#39393b",
  verifyEmail: 'rgba(255, 196, 65, 0.5)'
};
