export const round = (value, decimals) => {
  return Number(Math.round(value+'e'+decimals)+'e-'+decimals);
}

// get wave height from frequency range
export const calcSigWaveHeight = (spectralData, startFreq, endFreq) => {
  // console.log('spectralData:', spectralData)
  // console.log('from: ', startFreq, 'to: ', endFreq)
  let frequencies = Object.keys(spectralData)
    .filter(freq => freq >= startFreq && freq <= endFreq)
  // console.log('Frequencies:', frequencies)
  let sum = 0
  frequencies.forEach(freq => {
    const data = spectralData[freq]
    const bandwidth = data.bandwidth
    const energy = data.energy
    sum += energy * bandwidth
  })
  let waveHeight = 4 * Math.sqrt(sum)
  // console.log('waveHeight: ', waveHeight, 'm')
  return waveHeight
}
