import React, { useEffect, useRef } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { colors } from "../../constants/colors";

const Wave = (props) => {
  const { period, height, speed, color, thickness, direction } = props
  const sineWave = useRef(null);

  // build array of x values
  let xValues = []
  for (var i = 0; i <= 98; i++) {
    xValues.push(i)
  }

  let time = 0

  const animate = () => {
    const heightConst = 2
    const freqConst = 1.8
    const speedConst = 0.8

    // iterate over x values to make y vales for sine wave
    let sinePoints = xValues.map(x => {
      let frequency = 1 / period
      let y = height * heightConst *
        Math.sin(frequency * freqConst * (x + time * speedConst))
      return [x, y]
    })

    let path = "M" + sinePoints.map(p => {
      return p[0] + "," + p[1]
    }).join(" L")

    if (sineWave.current) {
      sineWave.current.setAttribute("d", path)
      time += speed / 100
      requestAnimationFrame(animate)
    }
  }

  // Styles
  const useStyles = makeStyles({
    root: {},
    path: {
      stroke: color,
      strokeWidth: thickness,
      strokeLinecap: 'round',
      fill: 'none',
      transform: `translate(50%, 50%) rotate(${direction -90 + 'deg'})`,
      transition: 'transform 1s ease'
    }
  });

  const classes = useStyles();

  useEffect(() => {
    animate()
  });

  return(
    <path className={classes.path} ref={sineWave}>
    </path>
  )
}

// Set default props
Wave.defaultProps = {
  color: colors.orange,
  thickness: 3,
  speed: 20,
  height: 2,
  period: 8,
  direction: 320,
  // waveLength: 20,
};

export default Wave
