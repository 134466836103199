import { buoys } from '../constants/buoys'
import {
  REQUEST_DATA,
  RECEIVE_DATA,
  RECEIVE_WIND_DATA
} from '../actions'

const initialState = {
  buoys: { ...buoys },
  standardMeteo: null,
  specCombo: null,
  wind: null
  // buoyData: {
  //   byId: {
  //     44006: {
  //       meteo: {
  //         'current': {},
  //         '5day': {},
  //         '45day': {},
  //       },
  //       spectralSummary: {
  //         'current': {},
  //         '5day': {},
  //         '45day': {},
  //       },
  //       spectralRaw: {
  //         'current': {},
  //         '5day': {},
  //         '45day': {},
  //       }
  //     }
  //   },
  //   allIds: {}
  // }
}

export default function(state = initialState, action) {
  switch (action.type) {
    case RECEIVE_DATA:
    if (action.dataType === 'meteo') {
      return {
        ...state,
        standardMeteo: action.data,
      };
    } else if (action.dataType === 'spec_combo') {
      return {
        ...state,
        specCombo: action.data,
      };
    }

    case RECEIVE_WIND_DATA:
      return {
        ...state,
        wind: action.data,
      };

    default:
      return state;
  }
}
