import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import isEmpty from "is-empty";

// My Components
import Centered from "../layout/Centered";

// MUI Imports
import { makeStyles } from '@material-ui/core/styles';
import { Typography, CircularProgress } from '@material-ui/core';

// Actions
import { getUser, resendVerificaitonEmail, sendFlash } from "../../actions/index";

const Dashboard = (props) => {

  const useStyles = makeStyles({
    root: {},
    subtitle: {
      color: 'rgb(119, 119, 119)'
    },
    popupBar: {
      backgroundColor: 'rgba(255, 196, 65, 0.5)',
      display: 'flex',
      flex: '1 1 auto',
      padding: '10px',
      justifyContent: 'center'
    }
  });

  const handleResendEmail = e => {
    props.resendVerificaitonEmail()
  }

  // Load user data on page load (via api)
  useEffect(() => {
    if (isEmpty(props.user)) {
      // fetch user data via api and store in redux
      console.log('getting user data...')
      props.getUser()
      if (props.location.search === '?verified') {
        props.sendFlash('Thanks for verifying your email :)', 'success')
      }
    }
  }, []);

  const classes = useStyles();

  if (!props.user.loading) {
    return(
      <div className={classes.root}>

        {/* Display warning if user email address isn't verified */}
        {'isVerified' in props.user && !props.user.isVerified &&
          <div className={classes.popupBar}>
            Please check your inbox and verify your email address | &nbsp;
            <Link
              to='#'
              onClick={() => handleResendEmail()}>
              Resend
            </Link>
          </div>
        }

        <Centered>
          <Typography variant="h4">
            <b>Hey there,</b> {props.user.firstName}
          </Typography>

          <p>First Name: {props.user.firstName}</p>
          <p>Last Name: {props.user.lastName}</p>
          <p>Email: {props.user.email}</p>
          <p>
            Email Verified: &nbsp;
            {props.user.isVerified ? '✅' : '❌' }
          </p>
          <p>
            Admin: &nbsp;
            {props.user.admin ? '✅' : '❌' }
          </p>
        </Centered>
      </div>
    )
  } else {
    return(
      <Centered>
        <CircularProgress />
      </Centered>
    )
  }
}

Dashboard.propTypes = {
  auth: PropTypes.object.isRequired
};

const mapDispatchToProps = {
  getUser,
  resendVerificaitonEmail,
  sendFlash
};

const mapStateToProps = state => ({
  auth: state.auth,
  user: state.user
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
