const initialState = {
  // viewId: viewIds.startPage
}

export default function(state = initialState, action) {
  switch (action.type) {
    // case CHANGE_VIEW:
    //   return {
    //     ...state,
    //     viewId: action.viewId
    //   };

    default:
      return state;
  }
}
