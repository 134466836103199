import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import isEmpty from "is-empty";

// MUI Imports
import { Typography, Container } from '@material-ui/core';
import { DataGrid, RowsProp, ColDef } from '@material-ui/data-grid';

// Actions
import { getAllUsers } from '../../actions/index';

// My Components
import Centered from "../layout/Centered";

const Admin = (props) => {

  const useStyles = makeStyles({
    root: {
      padding: '20px'
    }
  });

  // Load user data on page load (via api)
  useEffect(() => {
    if (isEmpty(props.allUsers)) {
      props.getAllUsers()
    }
  }, []);

  // console.log(props.allUsers)

  // Define table columns
  const columns: ColDef[] = [
    { field: 'firstName', headerName: 'First Name', width: 150 },
    { field: 'lastName', headerName: 'Last Name', width: 150 },
    { field: 'email', headerName: 'Email', width: 200 },
    { field: 'verified', headerName: 'Email Verified', width: 150 },
    { field: 'admin', headerName: 'Admin', width: 100 }
  ];

  let users = props.allUsers
  delete users.loading

  // Iterate over all users to create table rows
  const rows: RowsProp = Object.keys(props.allUsers).map(index => {
    let user = props.allUsers[index]
    return {
      id: index,
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      verified: user.isVerified ? '✅' : '❌',
      admin: user.admin ? '✅' : ''
    }
  })

  const classes = useStyles();

  return(
    <Container maxWidth='md'>
      <Typography variant='h3'>
        Users
      </Typography>
      <DataGrid rows={rows} columns={columns} autoHeight={true} loading={props.allUsers.loading} />
    </Container>
  )
}

const mapDispatchToProps = {
  getAllUsers
};

const mapStateToProps = state => {
  return {
    allUsers: state.allUsers
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Admin);
