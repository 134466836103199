export const tempOptions = {
 chart: {
   type: 'line',
   zoomType: 'x'
 },
 tooltip: {
   dateTimeLabelFormats: {
     day: '%B %e',
     hour: '%A %B %e %l:%M%P',
     minute: '%A %B %e %l:%M%P'
   },
   headerFormat: '<span style="font-size: 14px">{point.key}</span><br/>',
   style: {
     fontSize: '16px'
   },
   valueDecimals: 1,
   // valuePrefix: '$',
   valueSuffix: ' °F'
 },
  xAxis: {
    type: 'datetime',
    dateTimeLabelFormats: {
      // day: '%b %e',
      day: '%a %e',
      // hour: '%I:%M %p',
      hour: '%l:%M%P',
      minute: '%l:%M%P'
    }
  },
  yAxis: {
    title: {
      text: "Water Temperature"
    },
    // min: 40
  },
  credits: {
    enabled: false
  },
  legend: {
    enabled: false
  },
  title: {
    text: "Water Temperature"
  },
  time: {
    useUTC: false
  },
  series: [
    {
      type: 'line',
      // name: buoyDataTypes[props.dataType].name,
      connectedNulls: true,
      // data: chartData

      // zones: [{
      //   color: '#1276b6',
      //   value: 42
      // },
      // {
      //   color: {
      //     linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
      //     stops: [
      //       [0, '#ff0000'], // end
      //       [1, '#ffe600'] // start
      //     ]
      //   },
      //   value: 52
      // },
      // {
      //   color: '#2cbede',
      //   value: 62
      // },
      // {
      //   color: '#f9a639',
      //   value: 72
      // }]
    }
  ]
}
